var stickyHeader = true;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = true;
// var countUpStats = true;
// var countUpStatsDuration = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
var carouselBackgroundVideoYouTubeID = '3OKv84HfYeI'; // /watch?v=3OKv84HfYeI

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links

// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// window.addEventListener('resize', function () {...})
if (window.matchMedia("(min-width: 1025px)").matches) {
  $(".headerContent a.mainLogo").insertBefore(".headerContent ul.socialIcons");
  $(".headerText h1.title").wrapInner("<div class='innerTitle'></div>");
};

$(".footerBox[class*='ogos']").insertAfter(".pageFooter");

$(document).ready(function(){
  $('.mainCallToAction .matchfunding').first().appendTo('.mainCallToActionButtons');
})